/* eslint-disable */

import { useEffect } from "react";

export const dateFormate = (value: any) => {
    if (value !== "" && value) {
        return new Date(value ?? "")?.toISOString()?.split("T")[0];
    }
};
export const formatDate = (dateObj: any) => {
    const months = [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const month = months[dateObj.getMonth() + 1];
    const day = dateObj.getDate();
    const formattedDate = `${month} ${dateObj.getFullYear()}`;
    return formattedDate;
};
interface DataObject {
    [key: string]: string;
}
// Assuming the current month is July (7) and the current year is 2023
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1; // January is 0, so we add 1 to get the current month number

// Iterate through the data and update the months accordingly

// Helper function to get the month number from its name
export function getMonthNumber(monthName: any) {
    return new Date(`${monthName} 1, 2000`).getMonth() + 1;
}
export function shortFormNumber(value: number): string {
    const suffixes = ["", "K", "M", "B", "T"]; // Add more suffixes for larger numbers if needed

    const getSuffixIndex = (num: number): number => {
        const tier = (Math.log10(num) / 3) | 0;
        return Math.min(tier, suffixes.length - 1);
    };

    const getFormattedValue = (num: number, suffix: string): string => {
        return num.toFixed(1).replace(/\.00$/, "") + suffix;
    };

    const suffixIndex = getSuffixIndex(value);
    const divisor = Math.pow(10, suffixIndex * 3);
    const abbreviatedValue = value / divisor;

    return getFormattedValue(abbreviatedValue, suffixes[suffixIndex]);
}

export const rounder = (data: any[]) => {
    const Data: any[] = [];

    for (let i = 0; i < data.length; i++) {
        const entry: any = {};

        for (const key in data[i]) {
            if (
                ![
                    "row",
                    "id",
                    "targeted_id",
                    "comp_type",
                    "organic_type",
                    "dates",
                ].includes(key)
            ) {
                const num = parseFloat(data[i][key]);
                if (!isNaN(num)) {
                    entry[key] = Math.round(num);
                } else {
                    entry[key] = data[i][key];
                }
            } else {
                entry[key] = data[i][key];
            }
        }
        Data.push(entry);
    }
    return Data;
};

export function capitalize(input: string): string {
    if (!input) return "";

    const firstChar = input?.charAt(0)?.toUpperCase();
    const restOfStr = input?.slice(1);

    return `${firstChar}${restOfStr}`;
}

export function slugify(inputString: string) {
    if (inputString && typeof inputString === "string") {
        let slug = inputString?.toLowerCase();
        slug = slug?.replace(/[\s_]+/g, "_");
        return slug;
    }
    return "";
}
export function unsuglify(inputString: string) {
    if (!inputString) {
        return "";
    }
    const words = inputString?.split("_");
    const formattedWords = words?.map(
        (word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
    );
    const outputString = formattedWords?.join(" ");
    return outputString;
}

export function replaceEmptyWithDash(
    arr: Array<{ [key: string]: any }>
): any[] {
    function isEmpty(value: any) {
        if (Array.isArray(value) && value.length === 0) {
            return true;
        }

        if (typeof value === "object" && Object.keys(value).length === 0) {
            return true;
        }

        if (value === "") {
            return true;
        }

        return false;
    }

    function replaceEmpty(obj: { [key: string]: any }) {
        const result: { [key: string]: any } = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value === null || value === undefined || isEmpty(value)) {
                result[key] = "-";
            } else {
                result[key] = value;
            }
        }
        return result;
    }

    return arr.map(replaceEmpty);
}

export const parseString = (input: any): any[] => {
    if (!input) {
        return [];
    }

    const parts = input?.split(",");
    const nonEmptyParts = parts.filter(
        (part: any) =>
            part !== null && part !== undefined && part?.trim() !== ""
    );
    return [...new Set(nonEmptyParts)];
};
export function parseString2(input: string) {
    if (!input) {
        return [];
    }
    const values = input?.split(",");
    const result = [];

    for (const value of values) {
        if (value === "") {
            result.push(null);
        } else {
            result.push(value);
        }
    }

    return result;
}
export function parseString3(input: string) {
    if (!input) {
        return [];
    }
    const values = input?.split("%");
    const result = [];

    for (const value of values) {
        if (value === "") {
            result.push(null);
        } else {
            result.push(value);
        }
    }

    return result;
}
export const removeEmptyValues = (arr: string[]) => {
    return arr.filter(
        (value) => value !== "" && value !== undefined && value !== null
    );
};
export function removeDuplicatesByKey(array: any, key: string) {
    const uniqueArray = [];
    const dateMap = new Map();
    for (const item of array) {
        const dateKey = item?.[`${key}`];
        if (!dateMap.has(dateKey)) {
            dateMap.set(dateKey, true);
            uniqueArray.push(item);
        }
    }
    return uniqueArray || [];
}
export function isDateValid(dateString: string) {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(dateString)) {
        return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}
export function isEmpty(obj: any): boolean {
    if (Array.isArray(obj)) {
        return obj.length === 0;
    } else if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).length === 0;
    }
    return false;
}
export function ensureArray(input: any): any[] {
    return Array.isArray(input) ? input : [];
}
export const ensureObject = (input: any): any => {
    return typeof input === "object" ? input : {};
};
export function getLimitedItems<T>(arr: T[], n: number): T[] {
    if (n >= arr.length) {
        return arr;
    }
    return arr.slice(0, n);
}
export function formatNumber(num: any): number {
    const nm = Number(num);
    if (!isNaN(nm)) {
        if (nm?.toString()?.match(/\./)) {
            return Number(nm?.toFixed(2));
        } else {
            return Number(nm);
        }
    }
    return num;
}
export const symbolRemover = (inp: string): string | any => {
    typeof inp === "string" ? inp.replace(/[^a-zA-Z0-9]/g, "") : inp;
};

export function calculatePercentage(part: any, whole: any) {
    return Math.round((Number(part) / Number(whole)) * 100);
}

export function miliSecondToData(timestamp: number): string {
    if (isNaN(Number(timestamp))) {
        return "";
    }

    const date = new Date(Number(timestamp));

    // Extract the date components
    const day = date.getDate().toString().padStart(2, "0"); // Ensure two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Combine the components to form the formatted date
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}
export function hexToRgba(hex: string, opacity: number) {
    hex = hex.replace("#", "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const validOpacity = Math.min(1, Math.max(0, opacity));
    return `rgba(${r}, ${g}, ${b}, ${validOpacity})`;
}
export function formatNumberToK(number: number) {
    if (number < 1000) {
        return number.toString();
    } else if (number < 1000000) {
        const kValue = Math.floor(number / 1000);
        const remainder = number % 1000;
        const formattedNumber =
            remainder === 0
                ? `${kValue}k`
                : `${kValue}.${Math.floor(remainder / 100)}k`;
        return formattedNumber;
    } else {
        const millionValue = Math.floor(number / 1000000);
        const remainder = number % 1000000;
        const formattedNumber =
            remainder === 0
                ? `${millionValue}M`
                : `${millionValue}.${Math.floor(remainder / 100000)}M`;
        return formattedNumber;
    }
}
export function divideRange(min: number, max: number, pointsCount: number) {
    if (pointsCount <= 0) {
        console.error("Number of points must be greater than 0.");
        return [];
    }
    const interval = (max - min) / (pointsCount - 1);
    const result = Array.from({ length: pointsCount }, (_, index) =>
        Math.round(min + index * interval)
    );
    return result;
}

export const getRandomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const selectRowRemove = (str: string): string => {
    return str?.replace(new RegExp(`^select-row-`), "");
};
export const calculateDateDifferences = (arr: any[]) => {
    if (arr.length < 2) {
        return 0;
    }
    const max = Math.max(...arr.map((subArr) => subArr[0]));
    const secondMax = Math.max(
        ...arr.map((subArr) => subArr[0]).filter((el) => el !== max)
    );
    let maxInd = arr.findIndex((el) => el[0] === max);
    let max2Ind = arr.findIndex((el) => el[0] === secondMax);
    return arr[maxInd][1] - arr[max2Ind][1];
};

export const compareStr = (str1: any, str2: any) => {
    if (str1 && str2) {
        return (
            slugify(str1?.toString())?.toLowerCase()?.trim() ===
            slugify(str2?.toString())?.toLowerCase()?.trim()
        );
    }
    return false;
};
export const containsStr = (str1: string | any, str2: string, key?: string) => {
    if (str1 && str2) {
        return (
            typeof str1 === "object"
                ? ensureArray(str1)?.map((val: any) =>
                      (key ? val?.[key] : val)
                          ?.toString()
                          ?.toLowerCase()
                          ?.trim()
                  )
                : str1?.toString()?.toLowerCase()?.trim()
        ).includes(str2?.toString()?.toLowerCase()?.trim());
    }
    return false;
};
export function areArraysEqual(arr1: any[], arr2: any[]): boolean {
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
}
export const totalSum = (key: string, array: Array<any>) =>
    array.reduce(
        (sum, obj) => Math.abs(Number(sum)) + Math.abs(Number(obj[key] || 0)),
        0
    );
export const calculateAverage = (key: string, array: Array<any>) =>
    ((values) =>
        values.length
            ? values.reduce((sum, value) => sum + value, 0) / values.length
            : 0)(
        array
            .map((obj) => obj[key])
            .filter((value) => typeof value === "number")
    );
export const findExtremeDate = (
    key: string,
    array: Array<any>,
    mode: "oldest" | "latest" = "oldest"
) => {
    const dateValues = array.map((obj) => new Date(obj[key]).getTime());
    if (mode === "oldest") {
        return new Date(Math.min(...dateValues)).toISOString().split("T")[0];
    } else if (mode === "latest") {
        return new Date(Math.max(...dateValues)).toISOString().split("T")[0];
    } else {
        return null;
    }
};
export const findExtremeValue = (
    key: string,
    array: Array<any>,
    mode: "min" | "max" = "max"
) => {
    if (array.length === 0) {
        return 0;
    }
    const values = array.map((obj) => obj[key]);
    const extremeValue =
        mode === "min"
            ? Math.min(...values)
            : mode === "max"
              ? Math.max(...values)
              : 0;
    return extremeValue;
};

// export function extractDomain(url: any): string | null {
//     if (url && typeof url === "string") {
//         let modifiedUrl = url.replace(/^https?:\/\//, "");
//         modifiedUrl = modifiedUrl.replace(/^www\./, "");
//         modifiedUrl = modifiedUrl.replace(/\/.*$/, "");
//         const matches = modifiedUrl.match(
//             /([a-z0-9][a-z0-9\-]{1,63}\.[a-z\.]{2,6})$/i
//         );
//         return matches ? matches[0] : null;
//     }
//     return null;
// }

export function extractDomain(url: any): string | null {
    if (url && typeof url === "string") {
        let modifiedUrl = url.replace(/^https?:\/\//, "");
        modifiedUrl = modifiedUrl.replace(/^www\./, "");
        modifiedUrl = modifiedUrl.replace(/\/.*$/, "");
        const matches = modifiedUrl.match(
            /([a-z0-9][a-z0-9\-]{1,63}\.[a-z\.]{2,})$/i
        );
        // console.log(matches);
        return matches ? matches[0] : null;
    }

    return null;
}
export function domainFormate(url: any): string | null {
    if (url && typeof url === "string") {
        const pattern =
            /^(?:https?:\/\/)?(?:www\d?\.)?([a-z0-9-]+)(?:\.[a-z.]{2,})?/;
        const match = url?.toLowerCase()?.match(pattern);
        return match ? match[1] : null;
    }
    return null;
}
// export function removePunctuation(str: string) {
//     const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
//     const result = str?.replaceAll(punctuationRegex, "");
//     console.log(result);
//     return result;
// }

export function removePunctuation(str: string) {
    // Extended punctuation regex to include full-width punctuation
    // const punctuationRegex =
    //   /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~‚„“”†‡•…‰‹›€™：、。〃〈〉《》「」『』【】〔〕！？：；．，．／]/g;
    const punctuationRegex =
        /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~‚„“”†‡•…‰‹›‘’“”′″‵〃〈〉《》「」『』【】〔〕！？｡。､･ﾞﾟ：；．，／]/g;
    // Replace punctuation with an empty string
    // const punctuationRegex =
    //    /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～]/g;
    // const punctuationRegex =
    //     /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~‚„“”†‡•…‰‹›€™]/g;
    const result = str.replace(punctuationRegex, "");
    // console.log(result);
    return result;
}

export const extractMainDomain = (url: string) => {
    const domainRegex =
        /([0-9A-Za-z]{2,}\.[0-9A-Za-z]{2,3}\.[0-9A-Za-z]{2,3}|[0-9A-Za-z]{2,}\.[0-9A-Za-z]{2,3})$/;
    const match = domainRegex.exec(url);
    if (match && match.length > 1) {
        return match[1];
    }
    return "Invalid URL";
};
export function generateSearchQuery(data: { [key: string]: string }): string {
    let query = "?";
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}&`;
        }
    }
    if (query.endsWith("&")) {
        query = query.slice(0, -1);
    }
    return query;
}

export const encrypt = (text: string) => {
    return btoa(text);
};

export const decrypt = (text: string) => {
    try {
        return atob(text);
    } catch (error) {
        return "";
    }
};
